import * as React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import { EmojiFrown } from "react-bootstrap-icons"
import Layout from "../components/layout"

export const query = graphql`
  query getSiteMetadata {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default function NotFoundPage({ data }) {
  const { title } = data.site.siteMetadata

  return (
    <Layout>
      <Helmet>
        <title>{title} • Not found</title>
      </Helmet>
      <div className="container">
        <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto mt-5">
          <h1>Page not found</h1>
          <p>
            Sorry, the page you are looking for does not exist.{" "}
            <EmojiFrown size={22} viewBox="0 0 16 18" />
          </p>
          <Link to="/">Go home</Link>.
        </div>
      </div>
    </Layout>
  )
}
